
import { defineComponent } from "vue"
import { useRoute } from 'vue-router'
import store from "@/store"
import axios from "axios"
import querystring from 'querystring'
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  name: "ghl-callback",
  setup() {
    const accountNum = store.getters.activeAccountInfo.id
    const route = useRoute()
    let code = route.query.code;

    return {
      ghlIntegration: store.getters.currentGHLIntegration, code, accountNum
    }
  },
  async data() {
    if (this.code) {
      await this.storeTokens()
    }
  },
  methods: {
    async storeTokens() {
      // if code, call gohighlevel and hit footprint endpoint to store, close window
      const ax = axios.create({
        baseURL: "https://marketplace.gohighlevel.com",
      })
      // application/x-www-form-urlencoded
      const params = {
        'client_id': this.ghlIntegration.client_id,
        'client_secret': this.ghlIntegration.client_secret,
        'grant_type': 'authorization_code',
        'code': this.code,
        'user_type': 'Location',
        'redirect_uri': process.env.VUE_APP_FOOTPRINT_API_ENDPOINT.includes("staging") ? "https://staging.footprint-api.hlmtech.com/rx/hl-integration/callback" : "https://footprint-api.hlmtech.com/rx/hl-integration/callback"
      }

      console.log("=== try to send code token to GHL and get access token.... should respond to FP_Staging")
      console.log(params)

      const resp = await ax.post("https://services.leadconnectorhq.com/oauth/token", querystring.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })

      console.log("rsep", resp)

      // store response to Footprint API and close pop up
      if (resp) {
        ApiService.setHeader()

        let info = { ...resp.data }
        info.accountNum = this.accountNum
        info.guid = this.ghlIntegration.guid

        let fpResp
        try {
          fpResp = await ApiService.post('/rx/hl-integration/callback', { data: info })
        } catch (e) {
          console.error("Problem with GHL callback", e)
          return { message: "error updating team member" }
        }

        if (fpResp.status == 201) {
          // send return message
          window.opener.postMessage({
            message: "success",
            data: fpResp.data.data.attributes,
            id: fpResp.data.data.id
          }, window.location.origin); // may need "https://dh7qh4dr3kl2o.cloudfront.net"
        } else {
          window.opener.postMessage({ message: "fail" }, window.location.origin); // may need "https://dh7qh4dr3kl2o.cloudfront.net"
        }

        // close new window after sending return message
        // window.close();
      }

    }
  }
})
